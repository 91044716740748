import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'index', component: () => import("@/views/Index.vue"), meta: { title: '无邪生物科技- 强大的科研团队,致力于蜜蜂领域研究32年, 广州无邪生物科技有限公司' } },
  { path: '/aboutUs', name: 'aboutUs', component: () => import('@/views/AboutUs.vue'), meta: { title: '关于无邪-无邪生物科技' } },
  { path: '/coreTechnology', name: 'coreTechnology', component: () => import('@/views/CoreTechnology.vue'), meta: { title: '关于无邪-无邪生物科技' } },
  { path: '/productIdea', name: 'productIdea', component: () => import('@/views/ProductIdea.vue'), meta: { title: '关于无邪-无邪生物科技' } },
  { path: '/products', name: 'products', component: () => import('@/views/Products.vue'), meta: { title: '商品展示-无邪生物科技' } },
  { path: '/searchProducts', name: 'searchProducts', component: () => import('@/views/SearchProducts.vue'), meta: { title: '商品搜索-无邪生物科技' } },
  { path: '/productDetail', name: 'productDetail', component: () => import('@/views/ProductDetail.vue'), meta: { title: '商品展示-无邪生物科技' } },
  { path: '/news', name: 'news', component: () => import('@/views/News.vue'), meta: { title: '无邪资讯-无邪生物科技' } },
  { path: '/newsDetail', name: 'newsDetail', component: () => import('@/views/NewsDetail.vue'), meta: { title: '无邪资讯-无邪生物科技' } },
  { path: '/contact', name: 'contact', component: () => import('@/views/Contact.vue'), meta: { title: '联系我们-无邪生物科技' } },
  { path: '/404', name: 'notFound', component: () => import('@/views/Notfound.vue'), meta: { title: '404' } },
  { path: '/index', redirect: '/' },
  { path: '*', redirect: '/404' }
]

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

