import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import './assets/font.css';           //引入字体

import { Toast} from 'vant';

Vue.config.productionTip = false

// 绑定到原型
Vue.prototype.$axios = axios;
// 基地址
// axios.defaults.baseURL = "http://api.gzd1.net"
// axios.defaults.headers.post['Content-Type'] = 'application/json';

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // Toast.loading({
    //   duration: 0,
    //   message: '加载中...',
    //   forbidClick: true
    // });
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
// 响应拦截器
axios.interceptors.response.use(
  response => {
      return response.data;
    // Toast.clear()
    // if(response.data.code == 1){
    //   return response.data;
    // }else{
    //   Toast(response.data.msg)
    // }
  },
  error => {
    Toast('网络走丢了～')
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  // 路由发生变化修改页面title
  watch:{
    $route(to,from){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      window.pageYOffset = 0
      if(to.meta.title){
        document.title = to.meta.title
      }
    }
  },
  render: h => h(App)
}).$mount('#app')
